<script lang="ts" setup>
import { type FeaturingModel } from "~/lib/models";
import { BannerService } from "~/lib/services/banner";
import { FeaturingService } from "~/lib/services/featuring";
import { handleEither, handleError } from "~/shared/handlers";

const { antboxClient, tenant } = useAntbox();
const featuringService = new FeaturingService(antboxClient, tenant);
const bannerService = new BannerService(antboxClient, tenant);

const featuring = ref<FeaturingModel[]>();
const bannerImageURL = ref<URL>();

bannerImageURL.value = await bannerService.getBanner("home").then(handleEither).catch(handleError);

featuring.value = await featuringService
  .listFeaturing()
  .then(handleEither<FeaturingModel[]>)
  .catch(handleError);
</script>

<template>
  <section class="w-full">
    <BannerImage
      :banner-image="bannerImageURL"
      class-name="h-screen lg:max-h-[1080px] lg:bg-cover lg:bg-start bg-[right_-1rem] bg-[length:1000px_auto]"
    >
      <p class="text-white text-4xl font-play tracking-wide lg:text-6xl lowercase">proudly</p>
      <h2
        class="text-white text-5xl text-center mx-auto font-montsrt font-bold tracking-wider lg:text-7xl uppercase"
      >
        AFRICAN
      </h2>
    </BannerImage>
    <ul v-if="featuring">
      <FeaturingItem4Home
        v-for="(feature, idx) in featuring"
        :key="idx"
        :feature="feature"
        :is-first="idx === 0"
      />
    </ul>

    <ul v-else>
      <FeaturingHomeSkeleton :is-first="true" />
      <FeaturingHomeSkeleton />
    </ul>

    <p v-if="featuring && featuring?.length <= 0" class="w-full text-center p-32 text-white">
      Sem Featuring
    </p>

    <div
      id="editorial"
      class="w-full min-h-[65vh] bg-[url('/media/images/editorial-home-banner.png')] bg-cover bg-no-repeat bg-center flex items-center justify-center flex-col"
    >
      <h2 class="text-7xl font-play text-white mb-4">editorial</h2>
      <Button
        text="ver"
        @click="() => navigateTo('/editorial')"
        class="w-[120px] h-7 text-lg font-medium"
      />
    </div>
  </section>
  <Footer />
</template>
