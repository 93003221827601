<script lang="ts" setup>
import { type FeaturingModel } from "~/lib/models";

const actualImage = ref(0);
const router = useRouter();
const featuringImagePlacholder = "/media/images/sm-placeholder.png";

interface Props {
  feature: FeaturingModel;
  isFirst?: boolean;
}

const { feature } = defineProps<Props>();
</script>
<template>
  <li
    class="relative w-full h-auto bg-white flex flex-col items-center pt-11 lg:pt-0 lg:flex-row"
    :id="substituteWhiteSpacePerHifen(feature.name)"
  >
    <div
      class="flex flex-col items-center px-4 lg:w-2/4 order-1 lg:p-20"
      :class="isFirst ? 'lg:order-2 lg:items-start' : 'lg:items-end'"
    >
      <p class="font-play text-3xl text-secondary">featuring</p>
      <h2 class="featuring-name">{{ feature.name }}</h2>
      <strong class="featuring-role"> {{ feature.profession }} </strong>
      <p
        class="featuring-bio text-center w-[70%]"
        :class="isFirst ? 'lg:text-left' : 'lg:text-right'"
      >
        {{ feature.bio }}
      </p>

      <Button
        class="hidden lg:flex w-[150px] h-6 text-base mt-8"
        text="conhecer"
        @click="router.push(`/featuring#${substituteWhiteSpacePerHifen(feature.name)}`)"
      />
    </div>

    <div
      class="h-[400px] w-full lg:w-2/4 lg:min-h-[600px] overflow-hidden relative mt-14 lg:mt-0 order-2"
      :class="isFirst && 'lg:order-1'"
    >
      <img
        :src="feature.imagesUrl[actualImage]?.href || featuringImagePlacholder"
        class="w-full object-cover absolute"
      />

      <div class="absolute w-full h-full flex items-center justify-center">
        <Button
          class="w-[150px] h-6 text-base lg:hidden outline-none"
          text="conhecer"
          @click="router.push(`/featuring#${substituteWhiteSpacePerHifen(feature.name)}`)"
        />
      </div>
    </div>
  </li>
</template>
